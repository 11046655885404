import React from 'react';
import { Link } from 'react-router-dom';
import {
  bagOutline,
  cashOutline,
  chatboxOutline,
  chatbubbleOutline,
  documentTextOutline,
  gameControllerOutline,
  giftOutline,
  personAddOutline,
  personOutline,
  searchOutline,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useAuthStore } from '../store/zustand';
import { Role } from '../enums/role.enum';

const Sidebar = () => {
  const user = useAuthStore((state) => state.user);

  return (
    <div
      id="site__sidebar"
      className="fixed top-0 left-0 z-[99] pt-[--m-top] overflow-hidden transition-transform xl:duration-500 max-xl:w-full max-xl:-translate-x-full"
    >
      <div className="p-2 max-xl:bg-white shadow-sm 2xl:w-72 sm:w-64 w-[80%] h-[calc(100vh-64px)] relative z-30 max-lg:border-r dark:max-xl:!bg-bgbody dark:border-slate-800 ">
        <div className="pr-4" data-simplebar>
          <nav
            id="side"
            className="font-medium text-sm text-black pt-3 mt-2 dark:text-white"
          >
            <div className="px-3 pb-2 text-sm font-medium">
              <div className="text-black dark:text-white">Users</div>
            </div>

            <ul className="mt-2 -space-y-2" data-uk-nav="multiple: true">
              <li>
                <Link to="/user/search">
                  <IonIcon
                    icon={searchOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Discover</span>
                </Link>
              </li>
              <li>
                <Link to="/chats">
                  <IonIcon
                    icon={chatbubbleOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Messages</span>
                </Link>
              </li>
              <li>
                <Link to="/friend-requests/received">
                  <IonIcon
                    icon={personAddOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Friend Requests</span>
                </Link>
              </li>
            </ul>
          </nav>

          <nav
            id="side"
            className="font-medium text-sm text-black pt-3 mt-2 dark:text-white"
          >
            <div className="px-3 pb-2 text-sm font-medium">
              <div className="text-black dark:text-white">Waypoints</div>
            </div>

            <ul className="mt-2 -space-y-2" data-uk-nav="multiple: true">
              <li>
                <Link to="/waypoint/search">
                  <IonIcon
                    icon={searchOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Discover</span>
                </Link>
              </li>
              <li>
                <Link to="/waypoints">
                  <IonIcon
                    icon={chatboxOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Joined</span>
                </Link>
              </li>
            </ul>
          </nav>

          <nav
            id="side"
            className="font-medium text-sm text-black border-t pt-3 mt-2 dark:text-white dark:border-slate-800"
          >
            <div className="px-3 pb-2 text-sm font-medium">
              <div className="text-black dark:text-white">Account</div>
            </div>

            <ul className="mt-2 -space-y-2" data-uk-nav="multiple: true">
              <li>
                <Link to="/account">
                  <IonIcon
                    icon={personOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Settings</span>
                </Link>
              </li>
              <li>
                <Link to="/game-library">
                  <IonIcon
                    icon={gameControllerOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Game Library</span>
                </Link>
              </li>
              <li>
                <Link to="/account-orders">
                  <IonIcon
                    icon={documentTextOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Orders</span>
                </Link>
              </li>
              <li>
                <Link to="/account-products">
                  <IonIcon
                    icon={bagOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Purchased Games</span>
                </Link>
              </li>
            </ul>
          </nav>

          <nav
            id="side"
            className="font-medium text-sm text-black border-t pt-3 mt-2 dark:text-white dark:border-slate-800"
          >
            <ul>
              {user?.roles?.includes(Role.ADMIN_USER) && (
                <li>
                  <Link to="/partner-program">
                    <IonIcon
                      icon={cashOutline}
                      className="text-2xl flex items-center"
                    />
                    <span>Partner Program</span>
                  </Link>
                </li>
              )}
              <li>
                <Link to="https://store.playsum.live" target="_blank">
                  <IonIcon
                    icon={giftOutline}
                    className="text-2xl flex items-center"
                  />
                  <span>Game Store</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div
        id="site__sidebar__overly"
        className="absolute top-0 left-0 z-20 w-screen h-screen xl:hidden backdrop-blur-sm"
        data-uk-toggle="target: #site__sidebar ; cls :!-translate-x-0"
      ></div>
    </div>
  );
};

export default Sidebar;
